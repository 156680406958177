const SELECT_DATA = [
  {
    value: "Google search",
  },
  {
    value: "Other search engine",
  },
  {
    value: "thefourwinds.com",
  },
  {
    value: "Referral",
  },
  {
    value: "LinkedIn profile",
  },
  {
    value: "Others",
  },
];

export default SELECT_DATA;
